import React from 'react';
import AdminDashboardSideBar from 'components/admin-dashboard-sidebar';
import AdminDashboardHeader from 'components/admin-dashboard-header';
import { Outlet } from 'react-router-dom';
import withAdminAuthGuard from 'hocs/withAdminAuth';
import styles from './styles.module.css';

const AdminLayout = () => {
  return (
    <div className={styles.adminLayout_container}>
      <AdminDashboardSideBar />
      <div className={styles.adminLayout_subContainer}>
        <AdminDashboardHeader />
        <Outlet />
      </div>
    </div>
  );
};

export default withAdminAuthGuard(AdminLayout);
