import { useRoutes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { Constants } from 'utils/constants';
import FallBackLoader from 'components/fall-back-loader';
import AdminLayout from 'layout/adminlayout';

const AdminLogin = lazy(() => import('pages/auth/admin/adminlogin'));
const AdminDashBoard = lazy(() => import('pages/auth/admin/admindashboard'));
const Participants = lazy(() => import('pages/auth/admin/participants'));
const Winners = lazy(() => import('pages/auth/admin/winners'));
const AddQandA = lazy(() => import('pages/auth/admin/addQandA'));
const ListOfQandA = lazy(() => import('pages/auth/admin/listOfQAndA'));
const Reviews = lazy(() => import('pages/auth/admin/reviews'));
const ApproveReview = lazy(() => import('pages/auth/admin/approveReview'));

const PageNotFound = lazy(() => import('pages/page-not-found'));

const AppRoutes = () => {
  // CONSTANTS:
  const { routeNames } = Constants();

  const routes = useRoutes([
    {
      path: routeNames.adminLogin,
      element: <AdminLogin />,
    },

    {
      path: '',
      element: <AdminLayout />,
      children: [
        {
          path: routeNames.adminDashboard,
          element: <AdminDashBoard />,
        },
        {
          path: routeNames.participants,
          element: <Participants />,
        },
        {
          path: routeNames.reviews,
          element: <Reviews />,
        },
        {
          path: routeNames.winners,
          element: <Winners />,
        },
        {
          path: routeNames.addQAndA,
          element: <AddQandA />,
        },
        {
          path: routeNames.listOfQAndA,
          element: <ListOfQandA />,
        },
      ],
    },
    {
      path: `${routeNames.approveReview}/:id`,
      element: <ApproveReview />,
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ]);

  return <Suspense fallback={<FallBackLoader />}>{routes}</Suspense>;
};

export { AppRoutes };
