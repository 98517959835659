import React, { useState } from 'react';
import {
  Button,
  IconButton,
  Popover,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { Constants } from 'utils/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthContext } from 'hooks/useauthcontext';
import { Image } from 'components/image';
import { doritosLogo } from 'resources/images';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useMediaQuery } from '@mui/material';
import ReviewsIcon from '@mui/icons-material/Reviews';
import styles from './styles.module.css';

const AdminDashboardHeader = () => {
  // CONSTANTS:
  const { routeNames } = Constants();
  const isAbove480px = useMediaQuery('(min-width:480px)');

  // CONTEXTS:
  const { logOut, adminDetails } = useAuthContext();

  // ROUTING:
  const navigate = useNavigate();
  const location = useLocation();

  // STATE FOR PROFILE POPUP:
  const [anchorEl, setAnchorEl] = useState(null);

  // STATE FOR DRAWER:
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;

  const adminMenuData = [
    {
      route: '#',
      title: adminDetails?.email || 'Admin Email',
      icon: <AccountCircleIcon />,
    },
    {
      route: routeNames.adminDashboard,
      title: 'Dashboard',
      icon: <DashboardIcon />,
    },
    {
      route: routeNames.participants,
      title: 'Participants',
      icon: <GroupIcon />,
    },
    {
      route: routeNames.reviews,
      title: 'Reviews',
      icon: <ReviewsIcon />,
    },
    {
      route: routeNames.winners,
      title: 'Winners',
      icon: <EmojiEventsIcon />,
    },
    {
      route: routeNames.addQAndA,
      title: 'Add Q & A',
      icon: <AddCircleIcon />,
    },
    {
      route: routeNames.listOfQAndA,
      title: 'List Q & A',
      icon: <ListAltIcon />,
    },
  ];

  return (
    <div className={styles.adminDashboardHeader_container}>
      <h3 className={styles.adminDashboardHeader_heading}>
        Welcome To Doritos Dashboard !
      </h3>

      <Image
        image={doritosLogo}
        altText="doritosLogo"
        onClick={() => navigate(routeNames.adminDashboard)}
        customImageContainerStyle={styles.adminDashboardSideBar_logo}
      />

      <div className={styles.adminDashboardHeader_profileAndMenu}>
        <IconButton
          aria-describedby={id}
          onClick={handleProfileClick}
          color="primary"
          sx={{
            display: isAbove480px ? 'block' : 'none',
          }}
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleProfileClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={styles.adminDashboardHeader_popoverContent}>
            <Typography className={styles.adminDashboardHeader_name}>
              {adminDetails?.email}
            </Typography>
            <Button
              variant="outlined"
              color="error"
              startIcon={<LogoutIcon />}
              onClick={() => {
                logOut();
                navigate(routeNames.adminLogin);
              }}
            >
              Logout
            </Button>
          </div>
        </Popover>

        {/* Hamburger Menu for Mobile */}
        <IconButton
          aria-label="menu"
          className={styles.menuIcon}
          onClick={() => setIsDrawerOpen(true)}
        >
          <MenuIcon fontSize="large" />
        </IconButton>

        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <div className={styles.drawerContent}>
            <div className={styles.drawerHeader}>
              <Image
                image={doritosLogo}
                altText="doritosLogo"
                onClick={() => {
                  navigate(routeNames.adminDashboard);
                  setIsDrawerOpen(false);
                }}
                customImageContainerStyle={styles.adminDashboardSideBar_logo}
              />
              <IconButton
                onClick={() => setIsDrawerOpen(false)}
                className={styles.closeIcon}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>
            <List>
              {adminMenuData.map((menuItem, index) => (
                <ListItem
                  button
                  key={index}
                  selected={location.pathname === menuItem.route}
                  onClick={() => {
                    if (menuItem.route !== '#') {
                      navigate(menuItem.route);
                    }
                    setIsDrawerOpen(false);
                  }}
                  sx={{
                    border:
                      location.pathname === menuItem.route
                        ? '1px solid #1976d2'
                        : 'none',
                    borderRadius: '4px',
                    padding: '10px',
                  }}
                >
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText primary={menuItem.title} />
                </ListItem>
              ))}
            </List>

            <Button
              variant="contained"
              color="error"
              startIcon={<LogoutIcon />}
              className={styles.drawerLogoutButton}
              sx={{ marginTop: 'auto' }}
              onClick={() => {
                logOut();
                navigate(routeNames.adminLogin);
              }}
            >
              Logout
            </Button>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default AdminDashboardHeader;
