import {
  listOfQandA,
  participantsIcon,
  reviewsIcon,
  winnersIcon,
} from 'resources/images';

const Constants = () => {
  // ROUTE NAMES:
  const routeNames = {
    adminLogin: '/',
    adminDashboard: '/admindashboard',
    participants: '/participants',
    reviews: '/reviews',
    winners: '/winners',
    addQAndA: '/addQandA',
    listOfQAndA: '/listOfQandA',
    approveReview: '/approvereview',
  };

  // ADMIN DASHBOARD STATISTICS DATA

  const adminDashboardStatisticsData = [
    {
      icon: participantsIcon,
      label: 'Participants',
      count: '0',
      route: routeNames.participants,
    },
    {
      icon: winnersIcon,
      label: 'Winners',
      count: '0',
      route: routeNames.winners,
    },
    {
      icon: listOfQandA,
      label: 'List Of Q & A',
      count: '0',
      route: routeNames.listOfQAndA,
    },
    {
      icon: reviewsIcon,
      label: 'Reviews',
      count: '0',
      route: routeNames.reviews,
    },
  ];

  // LIST OF QandA COLUMNS

  const listOfQandAColumns = [
    { field: 'id', headerName: 'S.No' },
    { field: 'question', headerName: 'Question' },
    { field: 'answer', headerName: 'Answer' },
  ];

  // PARTICIPANTS COLUMNS

  const participantsColumns = [
    { field: 'id', headerName: 'S.No' },
    { field: 'date', headerName: 'Date' },
    { field: 'name', headerName: 'Name' },
    { field: 'batchCode', headerName: 'Batch Code' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'address', headerName: 'Address' },
    { field: 'city', headerName: 'City' },
    { field: 'postalCode', headerName: 'PostalCode' },
  ];

  // WINNER COLUMNS
  const winnerColumns = [
    { field: 'id', headerName: 'S.No' },
    { field: 'date', headerName: 'Date' },
    { field: 'name', headerName: 'Name' },
    { field: 'batchCode', headerName: 'Batch Code' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'address', headerName: 'Address' },
    { field: 'city', headerName: 'City' },
    { field: 'postalCode', headerName: 'PostalCode' },
  ];

  return {
    routeNames,
    adminDashboardStatisticsData,
    listOfQandAColumns,
    participantsColumns,
    winnerColumns,
  };
};

export { Constants };
