import React from 'react';
import { Navigate } from 'react-router-dom';
import { Constants } from 'utils/constants';

const withAdminAuthGuard = (WrappedComponent) => {
  // CONSTANTS
  const { routeNames } = Constants();

  return (props) => {
    const adminData = localStorage.getItem('adminData');

    if (adminData) {
      return <WrappedComponent {...props} />;
    }

    return <Navigate to={routeNames.adminLogin} replace />;
  };
};

export default withAdminAuthGuard;
