// ADMIN ENDPOINTS

export const adminLogin = '/admin/login';

// QUESTIONS
export const createQuestion = '/questions/save';
export const getAllQuestions = '/questions/all';
export const deleteQuestion = '/questions/delete/{id}';
export const editQuestion = '/questions/update';

// STATISTICS
export const getStatistics = '/admin/get-statistics';

// PARTICIPANTS
export const getAllParticipants = '/admin/get-all-users';

// WINNERS
export const getAllWinners = '/admin/get-all-winners';

// REVIEWS
export const getReviews = '/admin/get-pending-winners';

// REVIEW APPROVAL
export const reviewApproval = '/user/winner';
