import React from 'react';
import { Image } from 'components/image';
import {
  addQandAIcon,
  dashboardIcon,
  doritosLogo,
  listOfQandA,
  participantsIcon,
  reviewsIcon,
  winnersIcon,
} from 'resources/images';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Constants } from 'utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'hooks/useauthcontext';
import styles from './styles.module.css';

const AdminDashboardSideBar = () => {
  // CONSTANTS:
  const { routeNames } = Constants();

  // CONTEXTS

  const { logOut } = useAuthContext();

  // ROUTING:
  const navigate = useNavigate();
  const location = useLocation();

  const adminMenuData = [
    {
      route: routeNames.adminDashboard,
      title: 'Dashboard',
      icon: dashboardIcon,
    },
    {
      route: routeNames.participants,
      title: 'Participants',
      icon: participantsIcon,
    },
    {
      route: routeNames.reviews,
      title: 'Reviews',
      icon: reviewsIcon,
    },
    {
      route: routeNames.winners,
      title: 'Winners',
      icon: winnersIcon,
    },
    {
      route: routeNames.addQAndA,
      title: 'Add Q & A',
      icon: addQandAIcon,
    },
    {
      route: routeNames.listOfQAndA,
      title: 'List Q & A',
      icon: listOfQandA,
    },
  ];

  return (
    <div className={styles.adminDashboardSideBar_container}>
      <Image
        image={doritosLogo}
        altText="doritosLogo"
        onClick={() => navigate(routeNames.adminDashboard)}
        customImageContainerStyle={styles.adminDashboardSideBar_logo}
      />
      <div className={styles.adminDashboardSideBar_subContainer}>
        <div className={styles.adminDashboardSideBar_menuItemContainer}>
          {adminMenuData?.map((menuItem, index) => {
            const isActiveMenu = location.pathname === menuItem.route;
            return (
              <div
                key={index}
                className={
                  isActiveMenu
                    ? styles.adminDashboardSideBar_activeMenuItem
                    : styles.adminDashboardSideBar_menuItem
                }
                onClick={() => navigate(menuItem.route)}
              >
                <Image
                  image={menuItem.icon}
                  altText={menuItem.title}
                  customImageContainerStyle={
                    styles.adminDashboardSideBar_menuIcon
                  }
                />
                <h5 className={styles.adminDashboardSideBar_menuItemTitle}>
                  {menuItem?.title}
                </h5>
              </div>
            );
          })}
        </div>
        <Button
          variant="outlined"
          color="error"
          startIcon={<LogoutIcon />}
          onClick={() => {
            logOut();
            navigate(routeNames.adminLogin);
          }}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default AdminDashboardSideBar;
